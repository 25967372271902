import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const CSRaidsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Raids</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_raids.png"
            alt="Raids"
          />
        </div>
        <div className="page-details">
          <h1>Raids</h1>
          <h2>This guide contains information about the Raid mode.</h2>
          <p>
            Last updated: <strong>18/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="What are Raids?" />
        <p>
          Raids are a form of boss battle where you use a 16 employee team to
          fight a boss with high stats. Raid bosses have a chance to spawn every
          time you claim a finished dispatch mission, and they come in various
          different levels (lvl 70, 90, 110, 130 and 150).
        </p>
        <p>
          The levels which raid bosses can spawn at are determined by your
          branch’s Phase Coordinate Amplifier level.{' '}
        </p>
        <StaticImage
          src="../../../images/counterside/generic/raid_1.webp"
          alt="Raid"
        />
        <p>
          There are currently two different raid bosses; Britra, a CO, and the
          Inhibitor, a mech. The mechanics for both bosses will be described
          below.{' '}
        </p>
        <p>
          You can fight raids which have been shared by partners or guild
          members, and you are also able to share your own discovered raids with
          them. You can find raids from partners in the World Map, by clicking
          on the “Exploration Status” button on the bottom left and navigating
          to “Support Request”.{' '}
        </p>
        <StaticImage
          src="../../../images/counterside/generic/raid_2.webp"
          alt="Raid"
        />
        <SectionHeader title="What are the rewards?" />
        <p>
          For participating in or finishing a raid, you can either get a random
          piece of T4-T5 Britra/Inhibitor gear (depending on the boss you
          fought) or Britra/Inhibitor raid tokens. On top of that, you also get
          points which can be exchanged for rewards, as shown below. These
          rewards include set binaries, tuning binaries, more raid tokens, and
          gear selectors (unfortunately, you cannot select sets from them, only
          the piece type).
        </p>
        <StaticImage
          src="../../../images/counterside/generic/raid_3.webp"
          alt="Raid"
        />
        <SectionHeader title="What do I do with all my raid tokens?" />
        <p>
          You can use the tokens to craft raid gear in your workshop, as shown
          below. You can either craft a generic mold, which costs 65 tokens per
          piece, or a specific gear piece mold, which costs 90 tokens per piece.
          Do note that you cannot choose between Counter, Soldier, or Mech gear
          crafting.{' '}
        </p>
        <StaticImage
          src="../../../images/counterside/generic/raid_4.webp"
          alt="Raid"
        />
        <p>
          If the pieces you get from crafting are not what you want, it is
          recommended to delete them instead of foddering them into other gear
          for exp, because they give back some raid tokens when trashed.
        </p>
        <SectionHeader title="Specific Raid guides" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Raids - Britra"
            link="/counter-side/guides/raid-britra"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_raidbritra.jpg"
                alt="Raids"
              />
            }
          />
          <CategoryCard
            title="Raids - Inhibitor"
            link="/counter-side/guides/raid-inhibitor"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_raidinhi.jpg"
                alt="Raids"
              />
            }
          />
          <CategoryCard
            title="Raids - Kraken"
            link="/counter-side/guides/raid-kraken"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_raidkraken.jpg"
                alt="Raids"
              />
            }
          />
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default CSRaidsPage;

export const Head: React.FC = () => (
  <Seo
    title="Raids | Counter Side | Prydwen Institute"
    description="Guide for the Raid mode in Counter Side."
  />
);
